<template>
    <div>
      <section>
        <b-modal :active="isComponentModalActive" @close="closeModal">
          <div class="modal-card" style="height: 100vh; width: 960px !important;">
            <header class="modal-card-head">
              <p class="modal-card-title">Detalhes do template</p>
            </header>
            <section class="modal-card-body" style="overflow-y: auto !important">
              <b-tabs type="is-boxed">
                <b-tab-item label="Detalhes" icon="format-list-bulleted">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Nome">
                        <b-input v-model="template.name" readonly></b-input>
                      </b-field>

                      <b-field label="Categoria">
                        <b-input v-model="template.category" readonly></b-input>
                      </b-field>

                      <b-field label="Status">
                        <b-input v-model="template.status" readonly></b-input>
                      </b-field>

                      <b-field label="Componentes">
                        <div class="column">
                          <div v-for="componente in template.components" :key="componente.id">
                            <b-field v-if="componente.type" :label="componente.type">
                              <b-button v-if="componente.format === 'DOCUMENT' || componente.format === 'IMAGE' || componente.format === 'VIDEO'" class="button mb-4 if-info" outlined @click="() => viewFile(componente.example.header_handle[0])">Visualizar arquivo</b-button>
                              <div v-else-if="componente.type === 'BUTTONS'">
                                <div v-for="button in componente.buttons" :key="button.id">
                                  <b-button class="button mb-4 is-info" outlined>{{ button.text }}</b-button>
                                </div>
                              </div>
                              <b-input v-else v-model="componente.text" readonly class="mb-4" type="textarea" expanded></b-input>
                            </b-field>
                          </div>
                        </div>
                      </b-field>
                    </div>

                    <div class="column">
                      <TemplateChat :messages="messages" :rooms="rooms" />
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item :visible="buttons.length > 0" label="Funcionalidade Botôes" icon="cog">
                  <b-field v-for="button in buttons" :key="button.id">
                    <b-field :label="button.text" grouped>
                      <template #label>
                        {{ button.text  }}
                        <b-tooltip v-if="getFunctionHelp(button.function).length > 0" :label="getFunctionHelp(button.function)" position="is-top" multilined append-to-body>
                            <b-icon size="is-small" icon="help-circle-outline"></b-icon>
                        </b-tooltip>
                      </template>
                      <b-select placeholder="Selecione uma opção" v-model="button.function" expanded>
                        <option value="PAD" key="PAD">Seguir o fluxo normal de atendimento</option>
                        <option value="PADI" key="PADI">Seguir o fluxo normal de atendimento (para integrações)</option>
                        <option value="REA" key="REA">Redirecionar para atendente</option>
                        <option value="RED" key="RED">Redirecionar para departamento</option>
                        <option value="TAG" key="TAG">Adicionar tag ao contato</option>
                        <option value="CLO" key="CLO">Encerrar atendimento</option>
                      </b-select>
                      <b-select v-if="button.function === 'RED' || button.function === 'REA'" v-on:change.native="setAttendants" placeholder="Selecione um departamento" v-model="button.departmentId" expanded>
                        <option v-for="department in filteredDepartments" :value="department.id" :key="department.name">
                          {{ department.name }}
                        </option>
                      </b-select>
                      <b-select v-if="button.function === 'REA'" placeholder="Selecione um atendente" v-model="button.attendantId" expanded>
                        <option v-for="attendant in attendants" :value="attendant.id" :key="attendant.name">
                          {{ attendant.name }}
                        </option>
                      </b-select>
                      <b-select v-if="button.function === 'TAG'" placeholder="Selecione uma tag" v-model="button.tagId" expanded>
                        <option v-for="tag in getTags" :value="tag.id" :key="tag.title">
                          {{ tag.title }}
                        </option>
                      </b-select>
                    </b-field>
                  </b-field>
                </b-tab-item>
              </b-tabs>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-pulled-right" type="button" @click="closeModal">Fechar</button>
              <button class="button is-pulled-right is-success" type="button" @click="saveModal">Gravar</button>
            </footer>
          </div>
        </b-modal>
      </section>
    </div>
  </template>

<script>
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import TemplateChat from '../templates/TemplateChat.vue'
import mixin from '../../utils/mixins'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalTemplateMessage',
  components: {
    TemplateChat
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    updatingTemplate: {
      type: Object,
      required: false
    }
  },
  mixins: [mixin],
  data () {
    return {
      messages: [],
      messagesLoaded: true,
      loadingMessages: false,
      rooms: [],
      messagesPage: 1,
      urlPhotoShowing: '',
      template: {
        name: '',
        category: '',
        status: '',
        title: '',
        components: [],
        template_buttons: []
      },
      buttons: []
    }
  },
  watch: {
    buttons: {
      handler (val) {
        this.buttons.map(b => {
          switch (b.function) {
            case 'REA':
              b.attendantId = b.attendantId || null
              b.departmentId = b.departmentId || null
              b.tagId = null
              break
            case 'RED':
              b.attendantId = null
              b.tagId = null
              break
            case 'TAG':
              b.attendantId = null
              b.departmentId = null
              break
            case 'CLO':
            case 'PAD':
            default:
              b.attendantId = null
              b.departmentId = null
              b.tagId = null
              break
          }
        })
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['getUserId', 'allAttendantsEnabled', 'allDepartments', 'allTags']),
    attendants () {
      return this.allAttendantsEnabled
    },
    filteredDepartments () {
      return this.allDepartments
    },
    getTags () {
      return this.allTags.filter(t => t.enabled)
    },
    hasDefaultWithIntegration () {
      return this.buttons.filter(b => b.function === 'PADI').length > 0
    }
  },
  created () {
    this.fetchAttendants()
    this.fetchDepartments()
    this.fetchTags()
    if (this.updatingTemplate) {
      this.rooms = [this.adaptTicketToRoom(this.updatingTemplate)]
      this.messages = [this.adaptMessage(this.updatingTemplate)]
      this.template = this.updatingTemplate
      this.template.components = JSON.parse(this.updatingTemplate.json).components
      this.buttons = this.template.template_buttons.map(button => {
        return {
          text: button.button_id,
          attendantId: button.attendant_id,
          function: button.button_function,
          departmentId: button.department_id,
          tagId: button.tag_id
        }
      })
    }
  },
  methods: {
    ...mapActions(['fetchAttendants', 'fetchDepartments', 'fetchTags']),
    closeModal () {
      this.$emit('closeModal')
    },
    adaptTicketToRoom (template) {
      return {
        ...template,
        roomId: template.template_id,
        roomName: template.name,
        currentUserId: this.getUserId,
        unreadCount: 0,
        lastMessage: {
          content: `Ticket: ${template.id}`,
          timestamp: `${this.formatShortDate(template.created_at)}`,
          date: template.created_at
        },
        users: [
          {
            _id: this.getUserId,
            username: 'John Doe'
          },
          {
            _id: template.id,
            username: 'John Snow'
          }
        ],
        typingUsers: []
      }
    },
    adaptMessage (template) {
      const json = JSON.parse(template.json)
      let message = ''
      const file = []

      for (let i = 0; i < json.components.length; i++) {
        const component = json.components[i]
        if (component.type === 'HEADER') {
          if (component.format === 'TEXT') {
            message = this.getModifiedMessage(component.text)
          }

          if (component.format === 'DOCUMENT') {
            file.push({
              name: 'My File',
              type: 'pdf',
              url: component.example.header_handle[0]
            })
          }
        }

        if (component.type === 'BODY') {
          if (message.length > 0) {
            message += '\n\n'
          }
          message += this.getModifiedMessage(component.text)
        }

        if (component.type === 'FOOTER') {
          if (message.length > 0) {
            message += '\n\n'
          }
          message += this.getModifiedMessage(component.text)
        }
      }
      return {
        _id: template.template_id,
        content: message,
        timestamp: `${this.formatShortDate(Date.now())}`,
        type: 'text',
        senderId: this.getUserId,
        user: {
          _id: this.getUserId,
          username: 'John Doe'
        },
        files: file
      }
    },
    getModifiedMessage (message) {
      return message.replace(/<br>/g, '\n')
    },
    viewFile (url) {
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = 'download.pdf'
      a.click()
    },
    saveModal () {
      const buttons = this.buttons.map(b => {
        return {
          template_id: this.template.id,
          button_id: b.text,
          button_function: b.function,
          department_id: b.departmentId,
          attendant_id: b.attendantId,
          tag_id: b.tagId
        }
      })
      this.$emit('updateTemplate', { ...this.template, buttons })
    },
    getFunctionHelp (buttonFunction) {
      switch (buttonFunction) {
        case 'PAD':
          return 'Esta opção deve ser usada para o envio de mensagens pelo Chat. A resposta será processada como uma mensagem comum.'
        case 'PADI':
          return 'Esta opção deve ser usada para o envio de mensagens agendadas com perguntas, para que o click de um botão seja interpretado como resposta.'
        default:
          return ''
      }
    }
  }
}
</script>

  <style lang="scss" scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }
  .modal-card-body {
    overflow: visible !important;
  }
  .modal-card-foot {
    justify-content: flex-end;
  }
  .vac-room-item-open {
    border-color: aquamarine;
    border-width: 1px;
    border-style: solid;
    // animation: blinker 1.5s linear infinite;
  }
  .vac-container-scroll__margin-bottom {
    margin-bottom: 60px;
  }
  .vac-box-footer__hidden {
    display: none !important;
  }
  .select-attendant-div {
    background-color: aquamarine;
  }
  @keyframes blinker {
    50% {
      opacity: 30%;
    }
  }
  </style>
