<template>
    <div>
      <div v-if="allTemplates.length > 0">
        <b-table
          :data="allTemplates"
          :bordered="false"
          :striped="true"
          :narrowed="false"
          :hoverable="true"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
          :paginated="true"
          scrollable
        >
          <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="title" label="Título" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="enabled" label="Status" v-slot="props">
            {{ props.row.status }}
          </b-table-column>

          <b-table-column field="enabled" label="Categoria" v-slot="props">
            {{ props.row.category }}
          </b-table-column>

          <b-table-column field="enabled" label="Id Meta" v-slot="props">
            {{ props.row.template_id }}
          </b-table-column>

          <b-table-column width="200" label="Atualizar" v-slot="props">
            <b-button type="is-warning is-light" outlined @click="updateTemplate(props.row)">Atualizar</b-button>
          </b-table-column>

          <template #footer>
            <div class="has-text-left">
              Quantidade de registros {{ allTemplates.length }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </template>

<script>
import mixin from '../../utils/mixins'

export default {
  name: 'TemplatesList',
  props: {
    templates: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      templateDeletingId: null
    }
  },
  mixins: [mixin],
  computed: {
    allTemplates: {
      get () {
        return this.templates
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    updateTemplate (template) {
      this.$emit('updateTemplate', template)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
