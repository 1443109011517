<template>
    <div>
      <title-bar :title-stack="titleStack" />
      <hero-bar :has-right-visible="false">
        Templates
      </hero-bar>
      <section class="section is-main-section">
        <button @click="handleSyncTemplateButton"
            :loading="fetchingTemplates"
            class="button is-success mb-2 mr-2 is-pulled-right">
            Sincronizar templates
        </button>
      </section>
      <section class="section is-main-section">
        <ModalTemplateMessage v-if="isComponentModalActive" :isComponentModalActive="isComponentModalActive" :updatingTemplate="viewingTemplate" v-on:updateTemplate="handleAddTemplate" v-on:closeModal="closeModal"/>
        <b-loading v-if="fetchingTemplates" :is-full-page="true" v-model="fetchingTemplates" :can-cancel="false"></b-loading>
        <TemplatesList v-else :templates='allTemplates' v-on:updateTemplate="mountTemplate"/>
        <!--FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo template" /-->
      </section>
    </div>
  </template>

<script>

import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import TemplatesList from '@/components/templates/TemplatesList'
import ModalTemplateMessage from '@/components/modals/ModalTemplateMessage'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Templates',
  components: {
    HeroBar,
    TitleBar,
    TemplatesList,
    ModalTemplateMessage
  },
  data () {
    return {
      isComponentModalActive: false,
      viewingTemplate: null
    }
  },
  computed: {
    ...mapGetters(['allTemplates', 'fetchingTemplates']),
    titleStack () {
      return ['Cadastros', 'Templates']
    }
  },
  methods: {
    ...mapActions(['fetchTemplates', 'syncTemplates', 'updateTemplate']),
    mountTemplate (template) {
      this.viewingTemplate = template
      this.isComponentModalActive = true
    },
    closeModal () {
      this.viewingTemplate = null
      this.isComponentModalActive = false
    },
    async handleSyncTemplateButton () {
      try {
        await this.syncTemplates()
      } catch (error) {
        console.log(error)
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: `Não foi possível sincronizar os templates! <br> ${error}`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    handleAddTemplate (template) {
      try {
        this.updateTemplate(template)
      } catch (error) {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: `Não foi possível atualizar o template! <br> ${error}`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } finally {
        this.closeModal()
      }
    }
  },
  created () {
    this.fetchTemplates()
  }
}
</script>
